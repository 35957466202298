<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div class="" v-else>
        <!-- <div style="position: fixed; width: 100%; top: 0"> -->
        <weather :isShow="true" />
        <!-- </div> -->
        <!-- 第三行数据 -->
        <div class="content-box">
          <div>
            <peopleList
              :chuzhizuData="chuzhizuData"
              :peopleListData="peopleListData"
              :peopleInfo="peopleInfo"></peopleList>
          </div>
          <div class="map-box">
            <!-- <theMap></theMap> -->
            <iframe
              ref="iframeData"
              :src="htmlSrc"
              width="100%"
              height="100%"
              frameborder="0">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from '../utils/drawMixin'
import weather from './components/weather'
import peopleList from './peopleList'
import {
  rescueInfo,
  // rescueUserGroupInfo,
  // rescueUserDetailInfo,
  userMapPoint,
  pointUserDetail,
  organInfo
} from '../api/test'

// import theMap from './theMap'
// import tools from './tools'

export default {
  mixins: [drawMixin],
  data() {
    return {
      loading: true,
      openInfo: false,
      peopleInfo: null,
      chuzhizuData: [],
      peopleListData: {
        id: null,
        totalNum: '22',
        data: [
          {
            id: '7f3b1c7f81ba4641bfa6d7e2c10762ee',
            cadreName: '指挥长',
            name: '卢明安、唐洪军'
          },
          {
            id: '0f2df4adf7374e97a50a4c137798fd1a',
            cadreName: '副指挥长',
            name: '由淑明、李贵平、张卫华'
          },
          {
            id: 'd1599ee23ad146dfab0e400d29f038df',
            cadreName: '指挥组',
            name: '边防、谢德美、王国强、余元强'
          }
        ]
      },
      htmlSrc: 'static/test.html'
    }
  },
  components: {
    weather,
    peopleList
    // theMap
    // tools
  },
  mounted() {
    this.cancelLoading()
    this.getRescueInfo()
    // this.getRescueUserDetailInfo()
    window.addEventListener('message', (event) => {
      const res = event.data
      if (res.cmd == 'childHtml') {
        this.getPointUserDetail(res.params)

        // console.log(res, 'pppppppppppppp')
      }
    })
  },
  beforeDestroy() {},
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 查询现场指挥所列表
    getOrganInfo(id) {
      organInfo({ teamId: id, type: 0 }).then((res) => {
        console.log(res)
        if (res.data.data) {
          this.peopleListData = res.data.data[0]
        }
      })
    },
    // 查询处置组列表

    getChuzhizuInfo(id) {
      organInfo({ teamId: id, type: 1 }).then((res) => {
        console.log(res)
        if (res.data.data) {
          this.chuzhizuData = res.data.data.map((e) => {
            return {
              orgName: e.orgName,
              orgId: e.orgId,
              columns: [
                { label: '', prop: 'cadreName' },
                { label: '', prop: 'name' }
              ],
              listMap: e.listMap.map((el) => {
                return {
                  cadreName: el.cadreName,
                  name: el.name.split(',')
                }
              })
            }
          })
        }
      })
    },
    getRescueInfo() {
      rescueInfo().then((res) => {
        console.log(res.data, '12233333')
        if (res.data.data) {
          this.peopleInfo = res.data.data
          this.getOrganInfo(this.peopleInfo.id)

          this.getChuzhizuInfo(this.peopleInfo.id)

          // console.log(this.peopleInfo)
          // this.getRescueUserGroupInfo(this.peopleInfo.id)
          setTimeout(() => {
            this.sendCenterPosition()
          }, 2000)
          setTimeout(() => {
            this.getUserMapPoint()
          }, 2000)
          setInterval(() => {
            // console.log('ppppp')
            this.getUserMapPoint()
          }, 60000)
        }
      })
    },
    // 000000000000000000000000000000000000000000000000000000000000000000000000000000
    // getRescueUserGroupInfo(id) {
    //   rescueUserGroupInfo({ teamId: id }).then((res) => {
    //     if (res.data.data) {
    //       console.log(res.data.data)
    //       this.peopleListData[0] = res.data.data.map((e) => {
    //         return {
    //           ...e,
    //           userType: e.groupName
    //         }
    //       })
    //       // this.peopleListData[1] = res.data.data
    //     }
    //   })
    // },

    // 000000000000000000000000000000000000000000000000000000000000000000000000000000
    // getRescueUserDetailInfo() {
    //   rescueUserDetailInfo().then((res) => {
    //     if (res.data.data) {
    //       console.log(res.data.data, 'ppppppoooo')
    //       this.peopleListData[1] = res.data.data
    //     }
    //   })
    // },
    getUserMapPoint() {
      // console.log('ppppppoooo')
      userMapPoint({ teamId: this.peopleInfo.id }).then((res) => {
        if (res.data.data) {
          console.log(res.data.data, 'ppppppoooo')
          this.vueSendMsg(res.data.data)
          // this.peopleListData[1] = res.data.data
        }
      })
    },
    getPointUserDetail(data) {
      console.log(data, 'ddddddddddd')
      pointUserDetail({
        teamId: data.teamId,
        pointType: data.pointType,
        pointId: data.pointId
      }).then((res) => {
        // console.log(res)
        if (res.data.code == 500) {
          this.$message.error(res.data.msg)
        }
        if (res.data.data) {
          const iframeWindow = this.$refs.iframeData.contentWindow
          iframeWindow.postMessage(
            {
              cmd: 'info',
              params: {
                info: res.data.data,
                pointId: data.pointId,
                pointType: data.pointType,
                speed: data.speed
              }
            },
            '*'
          )
          console.log(res.data.data, 'rrrrrrrrrrr')
        }
      })
      // .catch((error) => {
      //   // if (res.params.teamId == null) {
      //   // }
      // })
    },
    sendCenterPosition() {
      const iframeWindow = this.$refs.iframeData.contentWindow
      iframeWindow.postMessage(
        {
          cmd: 'centerPosition',
          params: {
            info: this.peopleInfo
          }
        },
        '*'
      )
    },
    vueSendMsg(data) {
      console.log(';;;;;')
      const iframeWindow = this.$refs.iframeData.contentWindow
      iframeWindow.postMessage(
        {
          cmd: 'myVue',
          params: {
            info: data
          }
        },
        '*'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
.map-box {
  width: 1420px;
  height: 950px;
  padding: 50px 0 0;
}
.content-box {
  // margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-dialog {
  // background-image: url('../assets/tcbg.png');
  // background-size: 100% 100%;
  // background-color: #0e2241;
  // width: 1105px !important;
  // height: 694px !important;
  // margin-top: 10vh !important;
  .el-dialog__title {
    // color: #fff;
  }
  .el-dialog__header {
    padding: 16px 20px 10px;
  }
  .el-dialog__body {
    padding: 20px 38px;
    height: calc(100% - 55px);
    overflow: auto;
  }
}
.info-box {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 290px;
  width: 550px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 15px 30px #0000000f;
  padding: 20px;
}
.info-box .head-title {
  padding: 0 0 20px 0;
  font-size: 19px;
  font-weight: 600;
  color: #222;
}
.info-box .head-title .chahao {
  // width: 28px;
  font-size: 28px;
  font-weight: 400;
  float: right;
  // margin-left: auto;
  cursor: pointer;
}
.info-box .head-title .chahao:hover {
  color: #666;
}
.info-box .head-title .right {
  margin-right: 20px;
}
.info-box .box-body {
  // background-color: red;
  color: #222;
  font-size: 18px;
}
.info-box .box-body .list {
  margin-bottom: 15px;
}
.info-box .box-body .list .list-one {
  margin-bottom: 10px;
}
.info-box .box-body .list .left {
  padding-left: 40px;
}
.info-box .box-body .list .left .right {
  margin-right: 30px;
}
.info-box .box-body .list .big-title {
  color: #222;
  font-weight: 600;
  width: 92px;
}
</style>
